// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "F_fr";
export var marketplaceAppDevCaseSection = "F_fx";
export var marketplaceAppDevClientQuotes = "F_fz";
export var marketplaceAppDevIndustriesSection = "F_fq";
export var marketplaceAppDevOnlineSection = "F_fv";
export var marketplaceAppDevPracticesSection = "F_fw";
export var marketplaceAppDevPrimeSection = "F_fn";
export var marketplaceAppDevProcessSection = "F_fs";
export var marketplaceAppDevServicesSection = "F_fp";
export var marketplaceAppDevTechSection = "F_ft";
export var marketplaceAppExpertiseSection = "F_fy";